<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>

              <v-col cols="12">
                <v-card flat>

                  <div class="d-flex align-center justify-space-between pa-4">

                    <h3 class="font-weight-medium d-flex align-center">
                      <v-icon left color="gifty">mdi-credit-card-clock-outline</v-icon>
                      Historique des paiements
                    </h3>

                    <v-spacer/>

                    <v-btn :to="'/partner-vouchers/store/'+$route.params.id"
                           depressed
                           exact
                           icon
                           color="gifty"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                  </div>

                  <v-divider/>

                  <v-card-title>

                    <v-btn class="rounded-lg text-none" color="grey" text>
                      <v-icon color="gifty" left size="22">mdi-data-matrix-scan</v-icon>
                      Total paiements ({{ total }})
                    </v-btn>

                    <v-btn class="rounded-lg text-none" color="grey" text>
                      <v-icon color="success" left size="22">mdi-check-circle</v-icon>
                      Total utilisés ({{ statistics.usedCount }})
                    </v-btn>

                    <v-btn class="rounded-lg text-none" color="grey" text>
                      <v-icon color="error" left size="22">mdi-close-circle</v-icon>
                      Total non utilisés ({{ statistics.inUsedCount }})
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-overlay :value="overlay"
                             absolute
                             color="white"
                             opacity="0.5">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                    ></v-progress-circular>
                  </v-overlay>

                  <v-card-title>
                    <div>
                      <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Recherche..."
                          rounded
                          single-line
                      ></v-text-field>
                    </div>

                    <v-spacer/>

                    <div class="d-lg-block d-none">
                      <v-btn color="grey"
                             text
                             @click="$refs.filterDialog.open()">
                        <v-icon left>mdi-filter-outline</v-icon>
                        Filtre
                      </v-btn>

                      <v-btn color="grey"
                             text
                             @click="handleFilter">
                        <v-icon left>mdi-refresh</v-icon>
                        Mettre à jour
                      </v-btn>
                    </div>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text v-if="isLoading" class="pa-0">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>

                    <v-card-text class="pa-0">

                      <v-simple-table v-if="payments.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-no-wrap">En ligne</th>
                            <th class="text-no-wrap">Code QR</th>
                            <th class="text-no-wrap">Montant</th>
                            <th class="text-no-wrap">Utlisé</th>
                            <th class="text-no-wrap">Utlisé le</th>
                            <th class="text-no-wrap">Service</th>
                            <th class="text-no-wrap">Géneré le</th>
                            <th class="text-no-wrap">Status</th>
                            <th class="text-no-wrap">Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in payments" :key="i">

                            <td>
                              <v-icon color="success" v-if="item.user_id">mdi-check-circle</v-icon>
                              <v-icon color="red" v-else>mdi-close-circle</v-icon>
                            </td>

                            <td class="font-weight-medium">
                              <div class="pa-1">
                                <qrcode-vue
                                    :size="40"
                                    :value="item.code"
                                    background="transparent"
                                    foreground="#d60c56"/>
                              </div>
                            </td>

                            <td>
                              <span class="font-weight-medium gifty--text">
                                 {{ CurrencyFormatting(item.amount) }}
                                  </span>
                            </td>

                            <td>
                              <v-icon v-if="item.is_used" color="success">mdi-check-circle</v-icon>
                              <v-icon v-else color="error">mdi-close-circle</v-icon>
                            </td>


                            <td class="text-no-wrap">
                              <div v-if="item.used_at">
                                {{ item.used_at }}
                              </div>
                              <div v-else>
                                -
                              </div>
                            </td>

                            <td>
                              {{ item.service?.name }}
                            </td>

                            <td class="text-no-wrap">
                              {{ item.created_at }}
                            </td>

                            <td>
                              <div v-if="!item.is_refused">
                                <div v-if="item.is_used">
                                  <v-chip color="success" small dark>
                                    Utilisé
                                  </v-chip>
                                </div>
                                <div v-else>
                                  <div v-if="item.is_expired">
                                    <v-chip color="red" small dark>
                                      Expiré
                                    </v-chip>
                                  </div>
                                  <div v-else>
                                    <v-chip color="info" small dark>
                                      En attente
                                    </v-chip>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <v-chip color="orange" small dark>
                                  Refusé
                                </v-chip>
                              </div>
                            </td>

                            <td>
                              <v-btn :disabled="!!(item.is_used)"
                                     icon
                                     color="red"
                                     @click="removeItem(item.id)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </td>

                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getPaymentHistories"/>
                      </div>

                    </v-card-text>

                    <v-divider/>

                    <v-card-actions>

                      <v-row align="center" justify="space-between" class="flex-wrap">

                        <v-col cols="12" lg="2" md="2">
                          <v-select
                              v-model="perPage"
                              :items="[10, 20, 30, 50, 100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined
                          ></v-select>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols="12" sm="auto">
                          <v-pagination v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="5"
                              @input="getPaymentHistories"
                          ></v-pagination>
                        </v-col>
                      </v-row>
                    </v-card-actions>

                  </div>

                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>

      </div>

      <FilterDialog ref="filterDialog" @filter="handleFilter($event)"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "@/views/partner_vouchers/payment-history/componenets/FilterDialog"
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    FilterDialog,
    QrcodeVue,
    NoResults,
  },
  data() {
    return {
      id: null,
      isLoading: false,
      overlay: false,

      keyword: null,
      is_used: null,

      payments: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      statistics: {
        usedCount: 0,
        inUsedCount: 0,
      }

    };
  },
  methods: {
    getPaymentHistories() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/payment-histories?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword,
          dates: this.dates,
          is_used: this.is_used,
          store_id: this.$route.params.id,
        }
      }).then(res => {
        this.isLoading = false
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.payments = res.data.data.data
        this.statistics = res.data.statistics

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.is_used = e.is_used
      this.getPaymentHistories()
    },
    removeItem(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    handleDelete() {
      this.overlay = true;
      HTTP.post("/v1/partner-vouchers/delete-payment", {
        id: this.id,
      }).then(() => {
        this.$successMessage = "Ce paiement a été supprimé avec succès.";
        this.overlay = false;

        let index = this.payments.findIndex(el => el.id === this.id);
        if (index !== -1) {
          this.payments.splice(index, 1);
        }

        this.id = null;
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getPaymentHistories()
  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getPaymentHistories()
    },
    keyword() {
      this.getPaymentHistories()
    },
    $is_confirm: function (el) {
      if (el) {
        this.handleDelete();
        this.$is_confirm = false;
      }
    },
  },
};
</script>

<style scoped></style>
